import muiRootWrapper from "./mui-root-wrapper";
export const wrapRootElement = muiRootWrapper;

export const onRouteUpdate = ({ location, prevLocation }) => {
 
  if (location && location.state)
    location.state.referrer = prevLocation ? prevLocation.pathname : null;

};

