import { createMuiTheme } from "@material-ui/core/styles";
const pink = "#FF3366";
const blueNavy = "#34495E";
// const gris = "#303030";
const orange = "#F39C12";
// const green = "#27AE60";
// const black = "#373a3c";
// const grayDark = "#919AA8";
// const grayBlue = "#485262";
// const gray = "#CCD0D7";
const grayLight = "#E5E7EA";
const grayPale = "#f3f5f894";
const offWhite = "#F8FAFC";
const pumice = "rgb(210,215,211)";
// const facebook = "#3B5998";
// const instagram = "#c32aa3";
// const instagramBlue = "#4c5fd7";
// const instagramPurple = "#7232bd";
// const instagramOrange = "#f46f30";
// const instagramYellow = "#ffdc7d";
// const youtube = "#ff0000";
// const snapchat = "#FFFC00";
// const pinterest = "#BD081C";
// const twitter = "#1DA1F2";
// const linkedin = "#0077B5";
// const dbmLight = "#ABE600";
// const dbmDark = "#338D43";
// const indigo = "#6610f2";
// const purple = "#6f42c1";
// const red = "#dc3545";
// const yellow = "#ffc107";
// const teal = "#20c997";
// const cyan = "#17a2b8";
// const white = "#fff";
// const primary = "#FF3366";
// const secondary = "#34495E";
// const success = "#27AE60";
// const info = "#17a2b8";
// const warning = "#ffc107";
// const danger = "#dc3545";
const light = "#fff";
const dark = "#2d3748";
const gray = "#718096";

const theme = createMuiTheme({
  palette: {
    common: {
      primary: `${pink}`,
      secondary: `${blueNavy}`,
      orange: `${orange}`,
      grayLight: `${grayLight}`,
      offWhite: `${offWhite}`,
      pumice: `${pumice}`,
    },
    primary: {
      main: `${pink}`,
    },
    secondary: {
      main: `${blueNavy}`,
    },

    text: {
      secondary: `${light}`,
    },
  },

  section: {
    width: "100%",
    main: { padding: "5rem 0" },
    center: {
      width: "90vw",
      margin: "0 auto",
      display: "grid",
      alignItems: "center",
      height: "100%",
      maxWidth: 1300,
    },
    flexCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      textAlign: "center",
    },
    white: {
      backgroundColor: "white",
      padding: "2rem",
      textAlign: "center",
      marginBottom: "20px",
    },
    backgroundGrey: {
      backgroundColor: `${grayPale}`,
    },
  },

  icon: {
    marginBottom: 10,
    fontSize: 80,
    color: "white",
    borderRadius: "50%",
    padding: 20,
  },

  typography: {
    fontFamily: "Poppins,sans-serif",

    tab: {
      fontFamily: "Poppins,sans-serif",
      textTransform: "none",
      fontWeight: 700,
      fontSize: "1rem",
    },
    estimate: {
      fontSize: "1rem",
      textTransform: "none",
      color: "white",
      fontFamily: "Poppins,sans-serif",
    },

    h1: {
      color: `${dark}`,
      fontWeight: "700",
      lineHeight: "1.55",
      fontSize: "3.5rem",
    },
    h2: {
      color: `${dark}`,
      fontWeight: "700",
      fontSize: "2.5rem",
    },
    h3: {
      color: `${dark}`,
      fontWeight: "700",
      fontSize: "2.3rem",
    },
    h4: {
      color: `${dark}`,
      fontSize: "1.953rem",
    },
    h5: {
      color: `${gray}`,
      fontSize: "1.563rem",
    },
    h6: {
      color: `${gray}`,
      fontSize: "1.25rem",
    },

    subtitle1: {
      color: `${gray}`,
    },
    body1: {
      color: `${gray}`,
      fontSize: "1rem",
    },
    body2: {
      color: `${gray}`,
    },
  },
});

export default theme;
